/* Nav bar */

.header {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding: 20px;

  /* UI / White */

  background: #ffffff;
  /* Sticky header */

  box-shadow: 0 1px 5px rgba(204, 209, 221, 0.5), 0 1px 1px rgba(204, 209, 221, 0.4), 0 -1px 0 #ccd1dd;
}

.header .logo {
  height: 28px;
}

.header .title {
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 0;
}
