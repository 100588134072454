.input__lessheight {
  height: 2rem !important;
}

.TradeSecretsView .ts-event-key {
  text-align: right;
  background-color: #f6f6f6;
  border-right: #dfdfdf solid 1px;
  padding-top: 1rem;
}

.TradeSecretsView .ts-event-value {
  padding-top: 1rem;
}

.TradeSecretsView .ts-event-value__overflow {
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
  text-overflow: ellipsis;
}
