.External .button {
  border-radius: 5px;
  transition: all 0.25s ease-in-out;
  margin: 5px;
}
.External .button:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.External .title {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  /*margin: 20px 0;*/
}

.External .buttons {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.tree-element {
  margin: 0;
  position: relative;
}

.External {
  margin: 30px 0;
}
