ul.nav.nav-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  padding-bottom: 0px;
}

ul.nav.nav-tabs li.nav-item a.nav-link {
  cursor: pointer;
}

.TradeSecretsHolder .chevron,
.TradeSecretsConsumer .chevron {
  padding-right: 0.5rem;
  margin-right: 0.5rem;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgb(222, 226, 230);
}

.TradeSecretsHolder .action {
  padding-left: 1rem;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgb(222, 226, 230);
}

.TradeSecretsHolder form,
.TradeSecretsConsumer form {
  align-items: center;
  flex-wrap: wrap;
  justify-content: left;
  padding: 10px;
  text-align: left;
}

.TradeSecretsHolder form button,
.TradeSecretsConsumer form button {
  display: flex;
  margin-left: auto;
}

.TradeSecretsHolder table,
.TradeSecretsConsumer table {
  table-layout: fixed;
}

.TradeSecretsHolder .table thead tr th,
.TradeSecretsConsumer .table thead tr th {
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
}

.TradeSecretsHolder table tbody tr,
.TradeSecretsConsumer table tbody tr {
  cursor: pointer;
}

.TradeSecretsHolder table tr td,
.TradeSecretsConsumer table tr td {
  align-items: center;
  display: flex;
  text-align: left;
}

.td__wrap {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.TradeSecretsHolder table td.overflow,
.TradeSecretsConsumer table td.overflow {
  overflow: scroll;
  text-overflow: ellipsis;
}

.TradeSecretsHolder .toast,
.TradeSecretsConsumer .toast {
  max-width: 100%;
}

.TradeSecretsHolder .copyclipboard__ico {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: rgb(208, 152, 11);
  font-size: 20px;
  fill: rgb(208, 152, 11);
  width: 1em;
  height: 1em;
}

.TradeSecretsHolder .copyclipboard {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  cursor: pointer;
  display: flex;
  margin-left: auto;
}

.TradeSecretsHolder .sub-row {
}
.TradeSecretsHolder .sub-row__key,
.TradeSecretsConsumer .sub-row__key {
  text-align: right;
  background-color: #f6f6f6;
  border-right: #dfdfdf solid 1px;
  padding-top: 1rem;
}
.TradeSecretsHolder .sub-row__value,
.TradeSecretsConsumer .sub-row__value {
  padding-top: 1rem;
}
.TradeSecretsHolder .sub-row__value--overflow,
.TradeSecretsConsumer .sub-row__value--overflow {
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
  text-overflow: ellipsis;
}
