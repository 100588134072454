.Audit {
  height: 100%;
  text-align: left;
}
.title {
  text-align: center;
  font-size: 24px;
  margin: 20px 0;
}

.sub-header-row {
  background-color: #f8f8f8;
  border: #eeeeee solid 2px;
}

.header-row {
  background-color: #e5e5e5;
  border: #eeeeee solid 2px;
}

.header-row:hover {
  cursor: pointer;
}

.event-table {
  border: #dfdfdf solid 3px;
}

.event-key {
  text-align: right;
  background-color: #f6f6f6;
  border-right: #dfdfdf solid 1px;
  line-height: 50px;
}

.event-row td {
  height: 50px;
}

.event-value {
  line-height: 50px;
}

tr {
  padding-top: 15px;
  padding-bottom: 15px;
}

/* The animation code */
@keyframes dropdown {
  0% {
    height: 0;
    font-size: 0;
  }
  100% {
    height: 50px;
    font-size: 16px;
  }
}
/* The element to apply the animation to */
.event-row {
  animation-name: dropdown;
  animation-duration: 0.5s;
}
