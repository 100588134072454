.App {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
}

.body {
  margin-top: 150px;
  position: relative;
  width: inherit;
  padding: 20px;
  text-align: center;
}

.link {
  cursor: pointer;
  color: rgb(0, 123, 255);
  text-decoration: underline;
}
.link:hover {
  text-decoration: transparent;
}

.resetFilter {
  position: absolute;
  cursor: pointer;
  top: 1px;
  right: 1px;
  background: #fff;
  font-size: 19px;
  color: gray;
  padding: 3px 10px;
  border-radius: 0.25rem;
}
